import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Menu from "./menu";
import { request } from "../utils";

export default function ManageAdmins() {
  const history = useHistory();
  const [menu, setMenu] = useState(false);
  const [admins, setAdmins] = useState([]);

  useEffect(() => {
    request(`fetch-admin`)
      .then((res) => res.json())
      .then((res) => {
        setAdmins(res);
      });
  }, []);

  function removeAdmin(email) {
    if (email && email !== "") {
      request(`remove-admin/${email}`)
        .then((res) => res.json())
        .then((res) => {
          setAdmins(res);
        });
    }
  }

  return (
    <div className="admin-wrapper">
      <div className="parent-container-view admin-parent">
        <header>
          <div className="container">
            <div className="logo">
              <img src="../asset/logo.png" alt="" />
            </div>
            <nav>
              <Link to="/panel">Panel</Link>
              <Link to="/blog">Blog</Link>
              <Link to="/devotion">Devotions</Link>
              <Link to="/stream">Streams</Link>
              <Link to="/testimony">Testimonies</Link>
              <Link to="/announcement">Announcements</Link>
            </nav>
            <div>
              <i
                className="ri-menu-fill burger"
                onClick={() => setMenu(true)}
              ></i>
            </div>
          </div>
        </header>
        {menu && <Menu setMenu={setMenu} />}
        <div className="container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2 className="color-primary mt-40 center">Admins</h2>
            <button
              className="block color-primary"
              onClick={() => {
                history.push("/add-admins");
              }}
            >
              Add
            </button>
          </div>
          <div className="views mt-20">
            {admins ? (
              admins.map((each, id) => (
                <div className="view" key={id}>
                  <span className="block color-typo-primary">{each.email}</span>
                  {each.role !== "s_admin" && (
                    <button
                      className="block color-primary"
                      onClick={() => {
                        removeAdmin(each.email);
                      }}
                    >
                      Remove
                    </button>
                  )}
                  {each.role === "s_admin" && (
                    <p style={{ color: "white" }}>You</p>
                  )}
                </div>
              ))
            ) : (
              <span className="error-message">There is nothing here!</span>
            )}
          </div>
        </div>
        <footer className="mt-40">
          <div className="container">
            <div className="left">
              <span>
                Vine Branch Church Apata &copy; {new Date().getFullYear()}{" "}
              </span>
            </div>
            <div className="right">
              <span>
                Developed and Maintained by{" "}
                <a href="http://pyvot360.com">Pyvot360</a>
              </span>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}
