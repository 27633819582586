import React, { useState } from "react";
import { useHistory } from "react-router";
import {request} from '../utils';

const BroadcastMessage = () => {
  const history = useHistory();
  const [body, setBody] = useState("");
  const [title, setTitle] = useState("");
  const [time, setTime] = useState("");

  const sendMessage = () => {
    const parsed_time = time === "" ? Date.now() : Date.parse(time);

    if (body !== "" && title !== "") {

      const data = { title, body, time: parsed_time };

      request(`schedule-broadcast`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }).then(async (res) => {
        if (res.ok) {
          const text = await res.text();
          alert(text);
        } else {
          alert("error occurred please try again");
        }
      });
      
      history.push("/panel");
    } else alert("Add a title and paragraph");
  };

  return (
    <div className="edittor-container">
      <div className="edittor-wrapper">
        <div
          className="control-container"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <span onClick={sendMessage}>
            <i className="ri-send-plane-2-fill"></i>Send Broadcast
          </span>
          <span
            onClick={() => {
              setTime("");
              setBody("");
              setTitle("");
            }}
          >
            Clear
          </span>
        </div>
        <div className="edittor">
          <textarea
            placeholder="Message Title"
            className="title"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            value={title}
          ></textarea>
          <input
            type="text"
            placeholder="Message Body"
            onChange={(e) => {
              setBody(e.target.value);
            }}
            value={body}
          />
          <input
            style={{ marginTop: "30px", marginBottom: "30px" }}
            type="datetime-local"
            placeholder="Time"
            onChange={(e) => {
              setTime(e.target.value);
            }}
            value={time}
            id="datetime"
          />
          <span className='color-primary'>* Leave date and time empty to send message instantly.</span>
        </div>
      </div>
    </div>
  );
};

export default BroadcastMessage;
