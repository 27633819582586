import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Cookie from "js-cookie";
import { request } from "../utils";

const Admin = ({ setData, data }) => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [clicked, setClick] = useState(false);

  const login = async () => {
    setClick(true);

    fetch(`${process.env.REACT_APP_URL}admin-login`, {
      method: "POST",
      // mode: 'no-cors',
      headers: { "content-type": "application/json" },
      body: JSON.stringify({ email, password }),
    })
      .then((res) => res.json())
      .then((res) => {
        setClick(false);
        if (res.email && res.email !== "") {
          Cookie.set("jwt", res.jwt);
          setData({ ...res, jwt: undefined });
          // redirect to admin panel
          return history.push("/panel");
        }
        return alert("Invalid Login!");
      })
      .catch((err) => {
        alert("Invalid Login!");
        setClick(false);
      });
  };

  useEffect(() => {
    if (!data) {
      request("admin-login-auto")
        .then((res) => res.json())
        .then((res) => {
          if (![null, undefined, ""].includes(res.email)) {
            Cookie.set("jwt", res.jwt);
            setData({ ...res, jwt: undefined });
            // redirect to admin panel
            history.push("/panel");
          }
        });
    }
  }, [data, history, setData]);

  return (
    <div className="parent-container">
      <div className="form-container">
        <img src="../asset/logo.png" alt="" />
        <div className="form-area">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              login();
            }}
          >
            <input
              type="text"
              required
              placeholder="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <input
              type="password"
              required
              placeholder="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            {!clicked && <button>Login</button>}
            {clicked && (
              <button className="load-btn" disabled={true}>
                Please wait...
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
export default Admin;
