import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { request } from "../utils";

import Menu from "./menu";
const View = () => {
  const [menu, setMenu] = useState(false);
  const [announcement, setAnnouncement] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}fetch-notification`)
      .then((res) => res.json())
      .then((res) => {
        setAnnouncement(res);
      })
      .catch((err) => console.warn(err));
  }, []);

  const deleteAnnouncement = (id) => {
    request(`delete-notification/${id}`)
      .then((res) => {
        alert(`announcement ${id} deleted successfully!`);
      })
      .catch((err) => console.warn(err));
  };
  const pushAnnouncement = (id) => {
    request(`push-notification/${id}`)
      .then((res) => {
        alert(`announcement ${id} pushed successfully!`);
      })
      .catch((err) => console.warn(err));
  };

  return (
    <div className="admin-wrapper">
      <div className="parent-container-view admin-parent">
        <header>
          <div className="container">
            <div className="logo">
              <img src="../asset/logo.png" alt="" />
            </div>
            <nav>
              <Link to="/panel">Panel</Link>
              <Link to="/blog">Blog</Link>
              <Link to="/devotion">Devotions</Link>
              <Link to="/stream">Streams</Link>
              <Link to="/testimony">Testimonies</Link>
              <Link to="/announcement">Announcements</Link>
            </nav>
            <div>
              <i
                className="ri-menu-fill burger"
                onClick={() => setMenu(true)}
              ></i>
            </div>
          </div>
        </header>
        {menu && <Menu setMenu={setMenu} />}
        <div className="container">
          <h2 className="color-primary mt-40 center">All Announcements</h2>
          <div className="views mt-20">
            {announcement ? (
              announcement.map((each, id) => (
                <div className="view" key={id}>
                  <span className="block color-typo-primary">
                    {each.heading}
                  </span>
                  <button
                    className="block color-primary"
                    onClick={() => {
                      deleteAnnouncement(each.id);
                    }}
                  >
                    Delete
                  </button>
                  <button
                    className="block color-primary"
                    onClick={() => {
                      pushAnnouncement(each.id);
                    }}
                  >
                    Push
                  </button>
                </div>
              ))
            ) : (
              <span className="error-message">There is nothing here!</span>
            )}
          </div>
        </div>
        <footer className="mt-40">
          <div className="container">
            <div className="left">
              <span>
                Vine Branch Church Apata &copy; {new Date().getFullYear()}{" "}
              </span>
            </div>
            <div className="right">
              <span>
                Developed and Maintained by{" "}
                <a href="http://pyvot360.com">Pyvot360</a>
              </span>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default View;
