import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Cookie from "js-cookie";

import Menu from "./menu";

import { AppContext } from "../context";

const Panel = ({ data, setData }) => {
  const { blogs, devotional, streams, refresh, setRefresh } =
    useContext(AppContext);
  const history = useHistory();
  const [menu, setMenu] = useState(false);

  function logout() {
    Cookie.remove("jwt");
    setData(null);
    history.push("/");
  }

  return (
    <div className="parent-container-panel">
      <header>
        <div className="container">
          <div className="logo">
            <img src="../asset/logo.png" alt="" />
          </div>
          <nav>
            <Link to="/panel">Panel</Link>
            <Link to="/blog">Blog</Link>
            <Link to="/devotion">Devotions</Link>
            <Link to="/stream">Streams</Link>
            <Link to="/testimony">Testimonies</Link>
            <Link to="/announcement">Announcements</Link>
          </nav>
          <div>
            <i
              className="ri-menu-fill burger"
              onClick={() => setMenu(true)}
            ></i>
          </div>
        </div>
      </header>
      {menu && <Menu setMenu={setMenu} data={data} />}
      <div className="container">
        <h2 className=" color-primary mt-40"> Actions </h2>
        <div className="action-container grid mt-40">
          <div
            className="action"
            onClick={() => {
              history.push("/create-blog");
            }}
          >
            <i className="ri-newspaper-line color-primary"></i>
            <span className="color-typo-primary mt-20 block action-text">
              Add Blog
            </span>
          </div>
          <div
            className="action"
            onClick={() => {
              history.push("/create-stream");
            }}
          >
            <i className="ri-live-line color-primary"></i>
            <span className="color-typo-primary mt-20 block action-text">
              Add Live stream
            </span>
          </div>
          <div
            className="action"
            onClick={() => {
              history.push("/create-devotion");
            }}
          >
            <i className="ri-article-line color-primary"></i>
            <span className="color-typo-primary mt-20 block action-text">
              Add Devotion
            </span>
          </div>
          <div
            className="action"
            onClick={() => {
              history.push("/create-testimony");
            }}
          >
            <i className="ri-vip-diamond-line color-primary"></i>
            <span className="color-typo-primary mt-20 block action-text">
              Add Testimony
            </span>
          </div>
          <div
            className="action"
            onClick={() => {
              history.push("/create-announcement");
            }}
          >
            <i className="ri-notification-3-line color-primary"></i>
            <span className="color-typo-primary mt-20 block action-text">
              Add Announcement
            </span>
          </div>
          <div
            className="action"
            onClick={() => {
              history.push("/broadcast");
            }}
          >
            <i className="ri-broadcast-line color-primary"></i>
            <span className="color-typo-primary mt-20 block action-text">
              Broadcast Message
            </span>
          </div>
          {data && data.role === "s_admin" && (
            <div
              className="action"
              onClick={() => {
                history.push("/manage-admins");
              }}
            >
              <i className="ri-admin-line color-primary"></i>
              <span className="color-typo-primary mt-20 block action-text">
                Manage Admins
              </span>
            </div>
          )}
          <div className="action" onClick={() => setRefresh(!refresh)}>
            <i className="ri-refresh-line color-primary"></i>
            <span className="color-typo-primary mt-20 block action-text">
              Refresh
            </span>
          </div>
          <div className="action" onClick={logout}>
            <i className="ri-logout-box-r-line color-primary"></i>
            <span className="color-typo-primary mt-20 block action-text">
              Logout
            </span>
          </div>
        </div>
        <h2 className=" color-primary mt-40"> Recent Uploads </h2>
        {blogs && streams && devotional ? (
          <div className="recent-container grid mt-40">
            <div className="recent">
              <img src={blogs[0].url} alt="" />
              <span className="block color-typo-primary">Recent Blog</span>
              <Link to="/blog" className="block color-typo-primary web-link ">
                View All
              </Link>
            </div>
            <div className="recent">
              <img
                src={`https://i.ytimg.com/vi/${
                  streams[0].url.split("/")[3]
                }/maxresdefault.jpg`}
                alt=""
              />
              <span className="block color-typo-primary">Recent Stream</span>
              <Link to="/stream" className="block color-primary web-link ">
                View All
              </Link>
            </div>
            <div className="recent">
              <img src={devotional[0].url} alt="" />
              <span className="block color-typo-primary">
                Recent Devotional
              </span>
              <Link to="/devotion" className="block color-primary web-link ">
                View All
              </Link>
            </div>
          </div>
        ) : null}
      </div>
      <footer className="mt-40">
        <div className="container">
          <div className="left">
            <span>
              Vine Branch Church Apata &copy; {new Date().getFullYear()}{" "}
            </span>
          </div>
          <div className="right">
            <span>
              Developed and Maintained by{" "}
              <a href="https://pyvot360.com">Pyvot360</a>
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Panel;
