import { Link } from "react-router-dom";
import React from "react";

const menu = ({ data, setMenu }) => {
  return (
    <div className="mobile-nav">
      <div className="container">
        <i className="ri-close-line" onClick={() => setMenu(false)}></i>
        <nav>
          <Link to="/panel">Panel</Link>
          <Link to="/blog">Blog</Link>
          <Link to="/devotion">Devotions</Link>
          <Link to="/stream">Streams</Link>
          <Link to="/testimony">Testimonies</Link>
          <Link to="/announcement">Announcements</Link>
          {data && data.role === "s_admin" && (
            <Link to="/manage-admins">Manage Admins</Link>
          )}
        </nav>
      </div>
    </div>
  );
};

export default menu;
