import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { request } from '../utils';

const EditDevotion = () => {
  const history = useHistory();
  const { id } = useParams();
  const [verse_first, setFirstVerse] = useState("");
  const [verse_two, setSecVerse] = useState("");
  const [paragraph_1, setPar_1] = useState("");
  const [paragraph_2, setPar_2] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [prayer, setPrayer] = useState("");
  const [dev_date, setDevDate] = useState("");
  const updateDevotion = () => {
    const dev_details = {
    id,
      title,
      morning_verse: verse_first,
      morning_paragraph: paragraph_1,
      evening_verse: verse_two,
      evening_paragraph: paragraph_2,
      prayer,
      url: image,
      dev_date,
    };

    if (paragraph_1 !== "" && title !== "") {
      request(`update-devotion`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dev_details),
      }).then((res) => {
        // redirect to admin page
        alert("Devotion updated!");
        history.push("/devotion");
      });
    } else alert("Add a title and paragraph");
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}fetch-devotion/${id}`)
      .then((res) => res.json())
      .then((res) => {
        setImage(res.url);
        setPrayer(res.prayer);
        setTitle(res.title);
        setDevDate(res.dev_date);
        setPar_1(res.morning_paragraph);
        setFirstVerse(res.morning_verse);
        setPar_2(res.evening_paragraph);
        setSecVerse(res.evening_verse);
      })
      .catch((err) => {
        alert("error occurred!");
        history.push("/devotion");
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="edittor-container">
      <div className="edittor-wrapper">
        <div className="control-container">
          <span>
            <i className="ri-add-circle-line"></i> Add new paragraph
          </span>
          <span onClick={updateDevotion}>
            <i className="ri-send-plane-2-fill"></i> Update Devotion
          </span>
        </div>
        <div className="dev-edittor">
          <textarea
            placeholder="Devotion Title"
            className="title"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            value={title}
          ></textarea>
          <input
            type="text"
            placeholder="Image link"
            onChange={(e) => {
              setImage(e.target.value);
            }}
            value={image}
          />
          <img src={image} alt="" />
          <input
            type="text"
            placeholder="Morning verse"
            onChange={(e) => {
              setFirstVerse(e.target.value);
            }}
            value={verse_first}
          />
          <textarea
            placeholder="Write Something  cool..."
            className="paragraph"
            onChange={(e) => {
              setPar_1(e.target.value);
            }}
            value={paragraph_1}
          ></textarea>
          <input
            type="text"
            placeholder="Evening verse"
            onChange={(e) => {
              setSecVerse(e.target.value);
            }}
            value={verse_two}
          />
          <textarea
            placeholder="Write Something  cool..."
            className="paragraph"
            onChange={(e) => {
              setPar_2(e.target.value);
            }}
            value={paragraph_2}
          ></textarea>
          <input
            type="text"
            placeholder="Prayer"
            onChange={(e) => {
              setPrayer(e.target.value);
            }}
            value={prayer}
          />
          <input
            type="text"
            placeholder="Input devotion date"
            onChange={(e) => {
              setDevDate(e.target.value);
            }}
            value={dev_date}
          />
        </div>
      </div>
    </div>
  );
};

export default EditDevotion;
