import React, { useState } from "react";
import { useHistory } from "react-router";
import {request} from '../utils';

const CreateAnnouncement = () => {
  const history = useHistory();
  const [body, setBody] = useState("");
  const [title, setTitle] = useState("");
  const sendAnnouncement = () => {
    if (title === "" && body === "") {
      alert("All fields are required!");
      return;
    }

    const announcement_details = {
      heading: title,
      body: body,
      main: true,
    };

    console.log(announcement_details);

    if (body !== "" && title !== "") {
      request(`add-notification`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(announcement_details),
      }).then((res) => {
        // redirect to admin page
        alert("Announcement added!");
        history.push("/announcement");
      });
    } else alert("Add a title and paragraph");
  };

  return (
    <div className="edittor-container">
      <div className="edittor-wrapper">
        <div className="control-container">
          <span onClick={sendAnnouncement}>
            <i className="ri-send-plane-2-fill"></i> Post Announcement
          </span>
        </div>
        <div className="edittor">
          <textarea
            placeholder="Announcement Title"
            className="title"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            value={title}
          ></textarea>
          <input
            type="text"
            placeholder="Announcement Body"
            onChange={(e) => {
              setBody(e.target.value);
            }}
            value={body}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateAnnouncement;
