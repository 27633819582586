import "./styles/globals.css";
import "./public/remix-icon/remixicon.css";
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import AdminLogin from "./admin/index";
import AdminPanel from "./admin/panel";
import ViewBlog from "./admin/view-blog";
import ViewDevotion from "./admin/view-devotion";
import ViewStreams from "./admin/view-streams";
import ViewAnnouncements from "./admin/view-announcement";

import CreateBlog from "./editor/create-blog";
import CreateDevotion from "./editor/create-dev";
import CreateStream from "./editor/create-stream";
import CreateAnnouncement from "./editor/create-announcement";

import CreateTestimony from "./editor/create.testimony";
import EditTestimony from "./editor/EditTestimony";
import ViewTestimony from "./admin/view-testimony";

import EditBlog from "./editor/EditBlog";
import EditDevotion from "./editor/EditDevotion";
import EditStream from "./editor/EditStream";

import BroadcastMessage from "./editor/BroadcastMessage";

import { AppContextProvider } from "./context";
import ManageAdmins from "./admin/manage";
import AddAdmins from "./admin/add";
import SetPassword from "./admin/set-password";

function App() {
  const [data, setData] = useState(null);

  return (
    <div className="App">
      <AppContextProvider>
        <Router>
          <Switch>
            <Route
              exact
              path="/"
              render={(props) =>
                data ? (
                  <AdminPanel setData={setData} data={data} />
                ) : (
                  <AdminLogin data={data} setData={setData} />
                )
              }
            />
            <Route
              path="/panel"
              render={(props) =>
                data ? (
                  <AdminPanel data={data} setData={setData} />
                ) : (
                  <AdminLogin data={data} setData={setData} />
                )
              }
            />

            <Route
              path="/blog"
              render={(props) =>
                data ? (
                  <ViewBlog />
                ) : (
                  <AdminLogin data={data} setData={setData} />
                )
              }
            />
            <Route
              path="/devotion"
              render={(props) =>
                data ? (
                  <ViewDevotion />
                ) : (
                  <AdminLogin data={data} setData={setData} />
                )
              }
            />
            <Route
              path="/stream"
              render={(props) =>
                data ? (
                  <ViewStreams />
                ) : (
                  <AdminLogin data={data} setData={setData} />
                )
              }
            />
            <Route
              path="/testimony"
              render={(props) =>
                data ? (
                  <ViewTestimony />
                ) : (
                  <AdminLogin data={data} setData={setData} />
                )
              }
            />
            <Route
              path="/announcement"
              render={(props) =>
                data ? (
                  <ViewAnnouncements />
                ) : (
                  <AdminLogin data={data} setData={setData} />
                )
              }
            />

            <Route
              path="/create-blog"
              render={(props) =>
                data ? (
                  <CreateBlog />
                ) : (
                  <AdminLogin data={data} setData={setData} />
                )
              }
            />
            <Route
              path="/create-devotion"
              render={(props) =>
                data ? (
                  <CreateDevotion />
                ) : (
                  <AdminLogin data={data} setData={setData} />
                )
              }
            />
            <Route
              path="/create-stream"
              render={(props) =>
                data ? (
                  <CreateStream />
                ) : (
                  <AdminLogin data={data} setData={setData} />
                )
              }
            />
            <Route
              path="/create-testimony"
              render={(props) =>
                data ? (
                  <CreateTestimony />
                ) : (
                  <AdminLogin data={data} setData={setData} />
                )
              }
            />
            <Route
              path="/create-announcement"
              render={(props) =>
                data ? (
                  <CreateAnnouncement />
                ) : (
                  <AdminLogin data={data} setData={setData} />
                )
              }
            />

            <Route
              path="/edit-blog/:id"
              render={(props) =>
                data ? (
                  <EditBlog />
                ) : (
                  <AdminLogin data={data} setData={setData} />
                )
              }
            />
            <Route
              path="/edit-devotion/:id"
              render={(props) =>
                data ? (
                  <EditDevotion />
                ) : (
                  <AdminLogin data={data} setData={setData} />
                )
              }
            />
            <Route
              path="/edit-stream/:id"
              render={(props) =>
                data ? (
                  <EditStream />
                ) : (
                  <AdminLogin data={data} setData={setData} />
                )
              }
            />
            <Route
              path="/edit-testimony/:id"
              render={(props) =>
                data ? (
                  <EditTestimony />
                ) : (
                  <AdminLogin data={data} setData={setData} />
                )
              }
            />
            <Route
              path="/broadcast"
              render={(props) =>
                data ? (
                  <BroadcastMessage />
                ) : (
                  <AdminLogin data={data} setData={setData} />
                )
              }
            />
            <Route
              path="/manage-admins"
              render={(props) =>
                data && data.role === "s_admin" ? (
                  <ManageAdmins />
                ) : (
                  <AdminLogin data={data} setData={setData} />
                )
              }
            />
            <Route
              path="/add-admins"
              render={(props) =>
                data && data.role === "s_admin" ? (
                  <AddAdmins />
                ) : (
                  <AdminLogin data={data} setData={setData} />
                )
              }
            />
            <Route path="/set-password" render={(props) => <SetPassword />} />
          </Switch>
        </Router>
      </AppContextProvider>
    </div>
  );
}

export default App;
