import React, { useState } from "react";
import { useHistory } from "react-router";
import { request } from "../utils";

const CreateTestimony = () => {
  const history = useHistory();
  const [name, setName] = useState("");
  const [body, setBody] = useState("");

  const createTestimony = () => {
    const stream_details = {
      name,
      body,
    };

    if (body !== "" && name !== "") {
      request(`add-testimony`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(stream_details),
      }).then((res) => {
        // redirect to admin page
        alert("Testimony added!");
        history.push("/testimony");
      });
    } else alert("Add name and body");
  };

  return (
    <div className="edittor-container">
      <div className="edittor-wrapper">
        <div className="control-container">
          {/* <span onClick={(e) => { setPar(`${paragraph}** new paragraph **`) }}><i className="ri-add-circle-line"></i> Add new paragraph</span> */}
          <span onClick={createTestimony}>
            <i className="ri-send-plane-2-fill"></i> Create Testimony
          </span>
        </div>
        <div className="edittor">
          <input
            type="text"
            placeholder="Name"
            onChange={(e) => {
              setName(e.target.value);
            }}
            value={name}
          />
          <textarea
            placeholder="Testimony"
            className="body"
            onChange={(e) => {
              setBody(e.target.value);
            }}
            value={body}
          ></textarea>
        </div>
      </div>
    </div>
  );
};

export default CreateTestimony;
