import React, { useState } from "react";
import { Link } from "react-router-dom";
import Menu from "./menu";
import { request } from "../utils";

export default function AddAdmins() {
  const [email, setEmail] = useState("");
  const [menu, setMenu] = useState(false);
  const [clicked, setClick] = useState(false);

  function add() {
    if (email !== "") {
      request(`add-admins`, {
        method: "POST",
        // mode: 'no-cors',
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ emails: email.split(",").map((e) => e.trim()) }),
      })
        .then((res) => {
          alert("Admins added!");
          setEmail("");
        })
        .catch((err) => {
          alert("Invalid Login!");
        });
      setClick(false);
    }
  }

  return (
    <div className="parent-container-panel">
      <header>
        <div className="container">
          <div className="logo">
            <img src="../asset/logo.png" alt="" />
          </div>
          <nav>
            <Link to="/panel">Panel</Link>
            <Link to="/blog">Blog</Link>
            <Link to="/devotion">Devotions</Link>
            <Link to="/stream">Streams</Link>
            <Link to="/testimony">Testimonies</Link>
            <Link to="/announcement">Announcements</Link>
          </nav>
          <div>
            <i
              className="ri-menu-fill burger"
              onClick={() => setMenu(true)}
            ></i>
          </div>
        </div>
      </header>
      {menu && <Menu setMenu={setMenu} />}
      <div
        className="form-container"
        style={{
          height: "90vh",
          display: "grid",
          placeItems: "center",
          width: "100%",
        }}
      >
        <div className="form-area">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              add();
            }}
            style={{ width: "320px" }}
          >
            <input
              type="text"
              required
              placeholder="Emails (Seperated by comma ,)"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            {!clicked && <button>Continue</button>}
            {clicked && (
              <button className="load-btn" disabled={true}>
                Please wait...
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
