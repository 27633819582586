import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {request} from '../utils';

const EditBlog = () => {

  const history = useHistory();
  const { id } = useParams();
  const [image, setImage] = useState("");
  const [paragraph, setPar] = useState("");
  const [title, setTitle] = useState("");
  const updateBlog = () => {

    const blog_details = {
     id,
      title,
      body: paragraph,
      url: image,
    };

    if (paragraph !== "" && title !== "" && image !== "") {
      request(`update-post`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(blog_details),
      }).then((res) => {
        // redirect to admin page
        alert("Blog updated!");
        history.push("/blog");
      });
    } else alert("Add a title, paragraph and image");
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}fetch-post/${id}`)
    .then(res => res.json())
    .then(res => {
        setImage(res.url);
        setPar(res.body);
        setTitle(res.title);
    })
    .catch(err => {
        alert('error occurred!');
        history.push('/blog');
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="edittor-container">
      <div className="edittor-wrapper">
        <div className="control-container">
          <span
            onClick={(e) => {
              setPar(`${paragraph}** new paragraph **`);
            }}
          >
            <i className="ri-add-circle-line"></i> Add new paragraph
          </span>
          <span onClick={updateBlog}>
            <i className="ri-send-plane-2-fill"></i> Update Blog
          </span>
        </div>
        <div className="edittor">
          <textarea
            placeholder="Blog Title"
            className="title"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            value={title}
          ></textarea>
          <input
            type="text"
            placeholder="Image link"
            onChange={(e) => {
              setImage(e.target.value);
            }}
            value={image}
          />
          <img src={image} alt="" />
          <textarea
            placeholder="Write Something  cool..."
            className="paragraph"
            onChange={(e) => {
              setPar(e.target.value);
            }}
            value={paragraph}
          ></textarea>
        </div>
      </div>
    </div>
  );
};

export default EditBlog;
