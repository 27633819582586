/* eslint-disable no-restricted-globals */
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context";
import { request } from "../utils";

import Menu from "./menu";
const View = () => {
  const { blogs } = useContext(AppContext);
  const [menu, setMenu] = useState(false);

  const deleteBlog = (id) => {
    request(`delete-post/${id}`)
      .then((res) => {
        alert(`Post ${id} deleted successfully!`);
      })
      .catch((err) => console.warn(err));
  };

  return (
    <div className="admin-wrapper">
      <div className="parent-container-view admin-parent">
        <header>
          <div className="container">
            <div className="logo">
              <img src="../asset/logo.png" alt="" />
            </div>
            <nav>
              <Link to="/panel">Panel</Link>
              <Link to="/blog">Blog</Link>
              <Link to="/devotion">Devotions</Link>
              <Link to="/stream">Streams</Link>
              <Link to="/testimony">Testimonies</Link>
              <Link to="/announcement">Announcements</Link>
            </nav>
            <div>
              <i
                className="ri-menu-fill burger"
                onClick={() => setMenu(true)}
              ></i>
            </div>
          </div>
        </header>
        {menu && <Menu setMenu={setMenu} />}
        <div className="container">
          <h2 className="color-primary mt-40 center">All Blogs</h2>
          <div className="views mt-20">
            {blogs ? (
              blogs.map((each, id) => (
                <div className="view" key={id}>
                  <span className="block color-typo-primary">{each.title}</span>
                  <Link
                    className="block color-primary"
                    to={`/edit-blog/${each.id}`}
                  >
                    Edit
                  </Link>
                  <button
                    className="block color-primary"
                    onClick={() => {
                      deleteBlog(each.id);
                    }}
                  >
                    Delete
                  </button>
                </div>
              ))
            ) : (
              <span className="error-message">There is nothing here!</span>
            )}
          </div>
        </div>
        <footer className="mt-40">
          <div className="container">
            <div className="left">
              <span>
                Vine Branch Church Apata &copy; {new Date().getFullYear()}{" "}
              </span>
            </div>
            <div className="right">
              <span>
                Developed and Maintained by{" "}
                <a href="http://pyvot360.com">Pyvot360</a>
              </span>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default View;
