import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { request } from "../utils";

const EditTestimony = () => {
  const history = useHistory();
  const { id } = useParams();
  const [name, setName] = useState("");
  const [body, setBody] = useState("");
  const updateTestimony = () => {
    const details = {
      id,
      body,
      name,
    };

    if (name !== "" && body !== "") {
      request(`update-testimony`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(details),
      }).then((res) => {
        // redirect to admin page
        alert("Testimony updated!");
        history.push("/testimony");
      });
    } else alert("Add name and body");
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}fetch-testimony/${id}`)
      .then((res) => res.json())
      .then((res) => {
        setName(res.name);
        setBody(res.body);
      })
      .catch((err) => {
        alert("error occurred!");
        history.push("/testimony");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="edittor-container">
      <div className="edittor-wrapper">
        <div className="control-container">
          {/* <span onClick={(e) => { setPar(`${paragraph}** new paragraph **`) }}><i className="ri-add-circle-line"></i> Add new paragraph</span> */}
          <span onClick={updateTestimony}>
            <i className="ri-send-plane-2-fill"></i> Update Testimony
          </span>
        </div>
        <div className="edittor">
          <input
            type="text"
            placeholder="Name"
            onChange={(e) => {
              setName(e.target.value);
            }}
            value={name}
          />
          <textarea
            placeholder="Testimony"
            className="body"
            onChange={(e) => {
              setBody(e.target.value);
            }}
            value={body}
          ></textarea>
        </div>
      </div>
    </div>
  );
};

export default EditTestimony;
