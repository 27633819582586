import { createContext, useState, useEffect } from "react";

export const AppContext = createContext();

export function AppContextProvider({ children }) {
  const [blogs, setBlogs] = useState(null);
  const [devotional, setDevotional] = useState(null);
  const [streams, setStreams] = useState(null);
  const [testimony, setTestimony] = useState(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}fetch-post`)
      .then((res) => res.json())
      .then((res) => {
        setBlogs(res);
      })
      .catch((err) => console.warn(err));

    fetch(`${process.env.REACT_APP_URL}fetch-devotion`)
      .then((res) => res.json())
      .then((res) => {
        setDevotional(res);
      })
      .catch((err) => console.warn(err));

    fetch(`${process.env.REACT_APP_URL}fetch-stream`)
      .then((res) => res.json())
      .then((res) => {
        setStreams(res);
      })
      .catch((err) => console.warn(err));

    fetch(`${process.env.REACT_APP_URL}fetch-testimony`)
      .then((res) => res.json())
      .then((res) => {
        setTestimony(res);
        console.log(res);
      })
      .catch((err) => console.warn(err));
  }, [refresh]);

  return (
    <AppContext.Provider
      value={{ blogs, devotional, streams, testimony, refresh, setRefresh }}
    >
      {children}
    </AppContext.Provider>
  );
}
