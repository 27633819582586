import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { request } from "../utils";

const useQuery = () => new URLSearchParams(useLocation().search);

const SetPassword = () => {
  const history = useHistory();
  const _email = useQuery().get("email");
  const [password, setPassword] = useState("");
  const [clicked, setClick] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    request(`validate-admin`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({ email: _email }),
    }).then((res) => {
      if (res.status !== 200) return history.push("/");
      setLoading(false);
    });
  }, [history, _email]);

  const set = async () => {
    if (password === "") return alert("Please provide password");

    setClick(true);

    fetch(`${process.env.REACT_APP_URL}set-password`, {
      method: "POST",
      // mode: 'no-cors',
      headers: { "content-type": "application/json" },
      body: JSON.stringify({ email: _email, password }),
    })
      .then((res) => {
        if (res.status === 200) {
          alert("Password set successfully");
          history.push("/");
        } else {
          alert("An error occurred!");
        }
      })
      .catch((err) => {
        alert("An error occurred!");
      });
    setClick(false);
  };

  return (
    <div className="parent-container">
      <div className="form-container">
        <img src="../asset/logo.png" alt="" />
        <div className="form-area">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              set();
            }}
          >
            <input
              type="text"
              required
              placeholder="Email"
              value={_email}
              onChange={(e) => {
                e.preventDefault();
              }}
            />
            <input
              type="password"
              required
              placeholder="New Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            {!clicked && <button disabled={loading}>Continue</button>}
            {clicked && (
              <button className="load-btn" disabled={true}>
                Please wait...
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
export default SetPassword;
