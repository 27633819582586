import React, { useState } from "react";
import { useHistory } from "react-router";
import { request } from '../utils';

const CreateStream = () => {
  const history = useHistory();
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const sendStream = () => {
    const stream_details = {
      title,
      url: link,
      main: true,
    };

    if (link !== "" && title !== "") {
      request(`add-stream`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(stream_details),
      }).then((res) => {
        // redirect to admin page
        alert("Stream added!");
        history.push("/stream");
      });
    } else alert("Add a title and paragraph");
  };

  return (
    <div className="edittor-container">
      <div className="edittor-wrapper">
        <div className="control-container">
          {/* <span onClick={(e) => { setPar(`${paragraph}** new paragraph **`) }}><i className="ri-add-circle-line"></i> Add new paragraph</span> */}
          <span onClick={sendStream}>
            <i className="ri-send-plane-2-fill"></i> Post Stream
          </span>
        </div>
        <div className="edittor">
          <textarea
            placeholder="Stream Title"
            className="title"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            value={title}
          ></textarea>
          <input
            type="text"
            placeholder="Input Youtube link"
            onChange={(e) => {
              setLink(e.target.value);
            }}
            value={link}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateStream;
