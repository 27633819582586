import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import {request} from '../utils';

const EditStream = () => {
  const history = useHistory();
  const { id } = useParams();
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const updateStream = () => {
    const stream_details = {
        id,
      title,
      url: link,
      main: true,
    };

    if (link !== "" && title !== "") {
      request(`update-stream`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(stream_details),
      }).then((res) => {
        // redirect to admin page
        alert("Stream updated!");
        history.push("/stream");
      });
    } else alert("Add a title and paragraph");
  };

   useEffect(() => {
     fetch(`${process.env.REACT_APP_URL}fetch-stream/${id}`)
       .then((res) => res.json())
       .then((res) => {
         setLink(res.url);
         setTitle(res.title);
       })
       .catch((err) => {
         alert("error occurred!");
         history.push("/stream");
       });
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

  return (
    <div className="edittor-container">
      <div className="edittor-wrapper">
        <div className="control-container">
          {/* <span onClick={(e) => { setPar(`${paragraph}** new paragraph **`) }}><i className="ri-add-circle-line"></i> Add new paragraph</span> */}
          <span onClick={updateStream}>
            <i className="ri-send-plane-2-fill"></i> Update Stream
          </span>
        </div>
        <div className="edittor">
          <textarea
            placeholder="Stream Title"
            className="title"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            value={title}
          ></textarea>
          <input
            type="text"
            placeholder="Input Youtube link"
            onChange={(e) => {
              setLink(e.target.value);
            }}
            value={link}
          />
        </div>
      </div>
    </div>
  );
};

export default EditStream;
